import Dexie, { Table } from 'dexie';
import { Gate } from './Gate';
import { People } from './People';

export class MySubClassedDexie extends Dexie {

  gate!: Table<Gate, number>;
  people!: Table<People, number>;

  constructor() {
    super('StargateControl');
    this.version(1).stores({
      gate: '++id, show, &name, &address',
      people: '++id'
    });
  }
}

export const db = new MySubClassedDexie();