import { Box, Button, Container, Drawer, Grid } from '@mui/material';
import * as React from 'react';
import Glyph from './Glyph';

export interface IKeyboardProps {
  onLetterClick: (letter: string) => void;
  btnLabel?: string;
  btnVisible?: boolean;
}

export default function Keyboard({ btnVisible = false, ...props }: IKeyboardProps) {
  const [state, setState] = React.useState<boolean>(false);
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmn'.split('');

  const addAddress = (letter: string) => {
    props.onLetterClick(letter);
  }

  const toggleDrawer =
    (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState(open);
      };

  return (
    <>
      {btnVisible &&
        <Button variant='contained' onClick={toggleDrawer(true)}>{props.btnLabel}</Button>
      }
      <Drawer
        anchor="bottom"
        open={state}
        onClose={toggleDrawer(false)}
        BackdropProps={{ style: { background: 'none' } }}
      >
        <Box sx={{ border: 3 }}>
          <Container sx={{ my: 3 }}>
            <Grid container spacing={0.5} columns={8} justifyContent="center">
              {alphabet.map((letter, index) => (
                <Grid item xs={1} key={letter + index}>
                  <Button variant="outlined" sx={{ width: '100%' }} onClick={() => addAddress(letter)} >
                    <Glyph>
                      {letter}
                    </Glyph>
                    {letter}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Container >
        </Box>
      </Drawer>
    </>
  )
}
