import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';

export interface INoMatchProps {
}

export default function NoMatch (props: INoMatchProps) {
  return (
    <Box>
      <h2>Nothing to see here!</h2>
      <Typography>
        <Link to="/">Go to the home page</Link>
      </Typography>
    </Box>
  );
}
