import { Box, Card } from "@mui/material";
import { Outlet } from "react-router-dom";
import NavBar from "../../components/NavBar";
import React from "react";
// import "./LayoutGame.css";

export interface ILayoutGameProps {}

export interface IOutlet {
  dialling: (address: string | undefined) => {};
  chevron: string[];
  glyphGate?: string[];
  indexGate?: number;
  glyphAddress?: string[];
  indexAddress: number;
  gateOpen: boolean;
  setGateOpen: React.Dispatch<React.SetStateAction<boolean>>;
  diallingStop: () => {};
}

export default function LayoutGame(props: ILayoutGameProps) {
  const glyphGate = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklm".split("");
  const [glyphAddress, setGlyphAddress] = React.useState<string[]>([]);
  const [indexGate, setIndexGate] = React.useState<number>(0);
  const [indexAddress, setIndexAddress] = React.useState<number>(0);
  const [chevron, setChevron] = React.useState<string[] | null[]>([null, null, null, null, null, null, null]);
  const [intervalId, setIntervalId] = React.useState<NodeJS.Timeout | null>(null);
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);
  const [gateOpen, setGateOpen] = React.useState<boolean>(false);

  const calculateBestDirection = (currentIndex: number, indexAddress: number) => {
    const arrayLength = glyphAddress.length;
    const targetIndex = glyphGate.indexOf(glyphAddress[indexAddress]);
    const distancePositive = (targetIndex - currentIndex + arrayLength) % arrayLength;
    const distanceNegative = (currentIndex - targetIndex + arrayLength) % arrayLength;

    return distancePositive <= distanceNegative ? 1 : -1;
  };

  const start = (
    indexAddress: number = 0,
    direction: number = calculateBestDirection(0, 0),
    chevron_1: string[] | null[] = chevron
  ) => {
    chevron_1[indexAddress] = glyphAddress[indexAddress];
    setChevron(chevron_1);
    console.log("🚀 ~ file: Dialing.tsx:46 ~ setIndexAddress ~ nouveauTableau:", chevron_1);

    const newIntervalId = setInterval(() => {
      // Calcul du prochain index en bouclant à la fin du tableau
      setIndexGate((prevIndex) => {
        let nextGate: number;

        if (direction === 1) {
          nextGate = (prevIndex + 1) % glyphGate.length;
        } else {
          nextGate = (prevIndex - 1 + glyphGate.length) % glyphGate.length;
        }

        // Vérifier si l'élément actuel de Gate correspond à l'élément actuel de Address
        if (glyphGate[nextGate] === glyphAddress[indexAddress]) {
          // Si la correspondance est trouvée, incrémentez indexAddress
          clearInterval(newIntervalId);
          setIndexAddress((prevIndexAddress) => {
            const nextIndexAddress = prevIndexAddress + 1;

            if (nextIndexAddress != glyphAddress.length) {
              const nextDirection = calculateBestDirection(nextGate, nextIndexAddress);

              const newTimeoutId = setTimeout(() => {
                // Reprise de l'intervalle après la pause
                start(nextIndexAddress, nextDirection, chevron_1);
              }, 1000);

              setTimeoutId(newTimeoutId);
              return nextIndexAddress;
            } else {
              setGateOpen(true);
              return 0;
            }
          });
        }

        return nextGate;
      });
    }, 50);

    setIntervalId(newIntervalId);
  };

  const resetGate = () => {
    setGlyphAddress([]);
    setChevron([null, null, null, null, null, null, null]);
    setIndexGate(0);
    setIndexAddress(0);
  };

  React.useEffect(() => {
    if (!gateOpen && glyphAddress.length !== 0) start();
  }, [glyphAddress]);

  React.useEffect(() => {
    if (gateOpen) return;
    resetGate();
  }, [gateOpen]);

  const dialling = (address: string) => {
    if (gateOpen || !address || address.length === 0) return;
    const arrayAddress: string[] = address.split("");
    setGlyphAddress(arrayAddress);
  };

  const diallingStop = () => {
    if (intervalId) {
      clearInterval(intervalId);
      if (timeoutId) clearTimeout(timeoutId);

      resetGate();
    }
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", height: "100vh" }}>
      <NavBar gateOpen={gateOpen} />
      <Card sx={{ m: 3, p: 3 }} variant="outlined" style={{ width: "calc(100vh * 4 / 3)" }}>
        <Outlet
          context={{
            dialling,
            chevron,
            glyphGate,
            indexGate,
            glyphAddress,
            indexAddress,
            gateOpen,
            setGateOpen,
            diallingStop,
          }}
        />
      </Card>
    </Box>
  );
}
