import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

export interface INavBarProps {
  gateOpen: boolean;
}

export default function NavBar(props: INavBarProps) {
  const listeMenu = [
    { title: "Dialing", href: "" },
    { title: "Annuaire", href: "/annuaire" },
  ];

  return (
    <Box>
      {listeMenu.map((m, i) => (
        <Box key={"lien-" + i}>
          <Button component={Link} to={"/game" + m.href} variant="outlined">
            {m.title}
          </Button>
        </Box>
      ))}
    </Box>
  );
}
