import { Routes, Route } from "react-router-dom";

import NoMatch from "./pages/404";
import LayoutGame from "./pages/layouts/LayoutGame";
import Home from "./pages/Home";
import theme from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import Dialing from "./pages/Dialing/Dialing";
import LayoutMain from "./pages/layouts/LayoutMain";
import Annuaire from "./pages/Annuaire/Annuaire";
import { ThemeProvider } from "@emotion/react";
import React, { useState } from "react";

export const StargateContext = React.createContext<any>({
  count: 0,
  setCount: () => {},
});

export default function App() {
  const [count, setCount] = useState<number>(3);

  const updateCount = (newValue: number) => {
    setCount(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StargateContext.Provider value={{ count, updateCount }}>
        <Routes>
          <Route path="/" element={<LayoutMain />}>
            <Route index element={<Home />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
          <Route path="/game" element={<LayoutGame />}>
            <Route index element={<Dialing />} />
            <Route path="dialing/:address?" element={<Dialing />} />
            <Route path="annuaire" element={<Annuaire />} />
          </Route>
        </Routes>
      </StargateContext.Provider>
    </ThemeProvider>
  );
}
