import { Theme } from "@emotion/react";
import { Box, SxProps, Typography, TypographyPropsVariantOverrides } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { OverridableStringUnion } from "@mui/types";
import * as React from "react";

export interface IGlyphProps {
  sx?: SxProps<Theme> | undefined;
  variant?: OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides> | undefined;
  children?: string | undefined | null;
}

export default function Glyph(props: IGlyphProps) {
  return (
    <Typography component="span" className="glyphs" variant={props.variant} sx={props.sx}>
      {props.children}
    </Typography>
  );
}
