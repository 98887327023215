import * as React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import Keyboard from "../../components/Keyboard";
import Glyph from "../../components/Glyph";
import { db } from "../../services/db";
import { Gate } from "../../services/Gate";
import { useLiveQuery } from "dexie-react-hooks";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useOutletContext } from "react-router";
import { Link } from "react-router-dom";

export interface IAnnuaireProps {}

export default function Annuaire(props: IAnnuaireProps) {
  const [address, setAddress] = React.useState<string>("______A");
  const [nom, setNom] = React.useState<string>("");

  const gates = useLiveQuery(() => db.gate.where("show").equals(1).toArray());

  const handleAddressChange = (letter: string) => {
    // Faire quelque chose avec la nouvelle valeur de `address`
    const nouvelEtat = address.replace("_", letter);
    setAddress(nouvelEtat);
    console.log("Nouvelle adresse :", address);
  };

  React.useEffect(() => {
    if (gates?.length === 0) {
      const abydos: Gate = {
        name: "Abydos",
        address: "aGOfLdA",
        show: 1,
      };

      db.gate.add(abydos);
    }
  }, [gates]);

  const handleAddAddress = () => {
    const newAddress: Gate = {
      name: nom,
      address: address,
      show: 1,
    };

    db.gate
      .add(newAddress)
      .then(() => {
        console.log("Adresse ajoutée avec succès");
      })
      .catch((error: any) => {
        console.error("Erreur lors de l'ajout de l'adresse", error);
      });
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "address",
      headerName: "Address",
      width: 300,
      resizable: true,
      renderCell: (params: GridRenderCellParams<any>) => <Glyph variant="h4">{params.value}</Glyph>,
    },
    {
      field: "name",
      headerName: "Name",
      width: 400,
      resizable: true,
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      resizable: true,
      renderCell: (params: GridRenderCellParams<any>) => (
        <>
          <Button variant="outlined" component={Link} to={"/game/dialing/" + params.row.address} size="small">
            Dialing
          </Button>
          <Button variant="outlined" color="error" size="small">
            X
          </Button>
        </>
      ),
    },
  ];

  return (
    <Box>
      <Typography variant="h3">Annuaire</Typography>
      <DataGrid
        rows={gates || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
      />
      <Box sx={{ mt: 5 }}>
        <TextField label="Adresse" variant="outlined" disabled value={address} inputProps={{ className: "glyphs" }} />
        <TextField label="Nom" variant="outlined" onChange={(e) => setNom(e.target.value)} />
      </Box>
      <Keyboard btnVisible btnLabel="Keyboard" onLetterClick={handleAddressChange} />
      <Button onClick={handleAddAddress} variant="contained">
        Ajouter
      </Button>
    </Box>
  );
}
