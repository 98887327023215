import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#009999',
            contrastText: '#17202a',
        },
        secondary: {
            main: '#ffe57f',
            contrastText: '#17202a',
        },
        background: {
            default: '#17202a',
            paper: '#17202a',
        },
        text: {
            primary: '#009999',
            secondary: 'rgba(0,153,153,0.7)',
            disabled: 'rgba(0,153,153,0.5)',
        },
        success: {
            main: '#00994d',
            contrastText: '#17202a',
        },
        error: {
            main: '#990000',
            contrastText: '#17202a',
        },
        warning: {
            main: '#f1c40f',
        },
        info: {
            main: '#004d99',
            contrastText: '#17202a',
        },
        divider: '#009999',
    },
    typography: {
        fontFamily: 'Carrois Gothic SC',
        button: {
          textTransform: 'none'
        }
    },
})

export default theme