import { Box, Button, Card, Typography } from "@mui/material";
import * as React from "react";
import Glyph from "../../components/Glyph";
import { useOutletContext, useParams } from "react-router-dom";
import "./Dilaing.css";
import { IOutlet } from "../layouts/LayoutGame";
import { gsap } from "gsap";

export interface IDialingProps {}

export default function Dialing(props: IDialingProps) {
  const chevronStartRef = React.useRef<any[]>([]);
  const chevronDestinationRef = React.useRef<any[]>([]);
  const {
    dialling,
    chevron,
    glyphGate,
    indexGate,
    glyphAddress,
    indexAddress,
    setGateOpen,
    diallingStop,
    gateOpen,
  } = useOutletContext<IOutlet>();
  const { address } = useParams<{ address: string }>();

  React.useEffect(() => {
    if (!address || address.length === 0) {
      return;
    }

    //compositing(address);
  }, []);

  React.useEffect(() => {
    console.log("chevronDestinationRef", chevronDestinationRef);
    console.log("chevronStartRef", chevronStartRef);
    // if (boxRef) {
    //   const t: HTMLElement = boxRef.current!;
    // console.log(t.getBoundingClientRect());
    // console.log((t.style.scale = "2"));
    // }
    chevron.forEach((valeur: string, index: number) => {
      const ctx = gsap.context(() => {
        const s: HTMLElement = chevronStartRef.current[index];
        const d: HTMLElement = chevronDestinationRef.current[index];

        console.log("fab", s.offsetTop, d.offsetTop);
        gsap.to(chevronStartRef.current[index], {
          x: d.offsetLeft - s.offsetLeft + d.offsetWidth / 2,
          y: d.offsetTop - s.offsetTop + d.offsetHeight / 2 - 10, // à améliorer
          scale: 6,
        });
      });
      return () => ctx.revert();
    });
  }, [indexAddress]);

  return (
    <Box
      sx={{
        display: "grid",
        height: "100%",
        position: "relative",
      }}
    >
      <Card variant="outlined" sx={{ gridColumn: "1/5", gridRow: "1" }}>
        <Box
          sx={{
            display: "grid",
            height: "100%",
          }}
        >
          <Card variant="outlined" sx={{ gridColumn: "1", gridRow: "1/7" }}>
            <Box
              sx={{
                display: "grid",
                height: "100%",
              }}
            >
              <Card
                variant="outlined"
                sx={{ gridColumn: "1", gridRow: "1" }}
              ></Card>
              <Card
                variant="outlined"
                sx={{
                  gridColumn: "2/5",
                  gridRow: "1",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {chevron.map((valeur: string, index: number) => (
                  <Box
                    key={valeur + index}
                    ref={(a) => (chevronStartRef.current[index] = a)}
                    sx={{
                      position: "absolute",
                      scale: "20",
                      zIndex: "tooltip",
                    }}
                  >
                    <Glyph>{valeur}</Glyph>
                  </Box>
                ))}
              </Card>
            </Box>
          </Card>
          <Card variant="outlined" sx={{ gridColumn: "1", gridRow: "7" }}>
            <Box
              sx={{
                display: "grid",
                height: "100%",
                gridAutoFlow: "column",
              }}
            >
              <Card variant="outlined">
                <Button
                  onClick={() => dialling(address)}
                  color="success"
                  variant="contained"
                >
                  Dialling
                </Button>
                <Button
                  onClick={() => diallingStop()}
                  color="error"
                  variant="contained"
                >
                  Stop
                </Button>
                <Button
                  onClick={() => setGateOpen(false)}
                  color="info"
                  variant="contained"
                >
                  Close
                </Button>
              </Card>
              <Card
                variant="outlined"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h3" sx={{ position: "absolute" }}>
                  {gateOpen ? "Porte ouverte" : "Porte Fermé"}
                </Typography>
              </Card>
            </Box>
          </Card>
        </Box>
      </Card>
      <Card variant="outlined" sx={{ gridColumn: "5", gridRow: "1" }}>
        <Box
          sx={{
            height: "100%",
          }}
        >
          {chevron.map((valeur: string, index: number) => (
            <Card
              variant="outlined"
              key={index}
              sx={{
                height: "calc(100% / 7)",
                zIndex: "fab",
              }}
              ref={(a) => (chevronDestinationRef.current[index] = a)}
            ></Card>
          ))}
        </Box>
      </Card>
    </Box>
  );
}
