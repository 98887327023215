import { Box, Button } from '@mui/material';
import * as React from 'react';
// import './Home.css'

export interface IHomeProps {
}

export default function Home(props: IHomeProps) {
  const handleClick = () => {
    alert('Bouton cliqué !');
  };

  return (
    <Box>
      <h1>Bienvenue dans Stargate Control</h1>
      <Button href="/game" variant="outlined">
        Continuer
      </Button>
    </Box>
  );
}
